import * as React from 'react'
import { Box } from '@mui/material'
import StepSample from './StepSample'
import StepPatient from './StepPatient'
import StepClinical from './StepClinical'
import dayjs, { Dayjs } from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { ProgressPencil } from 'mdi-material-ui'

const sampleSchema = yup.object().shape({
  sampleName: yup.string().required(),

  // projectId: yup.number().required(),

  // genePanel: yup.string().required(),
  // targetRegionId: yup.string().required(),
  sampleSource: yup.string().required()

  // type: yup.string().required(),
  // genomeBuild: yup.string().required(),
  // genePanel: yup.string().required(),
})

const patientSchema = yup.object().shape({
  // firstName: yup.string().required(),
  // doctor: yup.string().required(),
  // race: yup.string().required(),
  // lastName: yup.string().required()
  // birthday: yup.string().required(),
  // gender: yup.string().required(),
  // acceptDate: yup.string().required(),
  // tooptip: yup.array().required()
})
const clinicalSchema = yup.object().shape({})

interface Props {
  formField: {
    sample: {
      sampleName?: string
      sampleSource?: string
      type?: string
      genomeBuild?: null | 'hg19' | 'hg38'
      projectId?: number | string[] | null
      genePanel?: string | number | string[] | null
      targetRegionId?: number[] | number | null
      prefix?: string
      fileSize?: number
    }
    clinical: {
      termList: any[]
      clinicalInformation?: string
    }
    patient: {
      patientName: string
      birthday: Dayjs | null | string
      doctor: string
      infirmaryName: string
      departmentName: string
      caseNumber: string
      gender: string | null
      race: string | null
      acceptDate: Dayjs | null | string
      tooptip: string[]
      status: boolean
      removeDuplication: boolean
      hasSv: boolean
      svFile: string
      svName: string
      hasCnv: boolean
      cnvFile: string
      cnvName: string
      isFq: boolean
      fqFiles: any[]
    }
  }
  getValues: any
  index: number
  handleUploads: any
  sampleType: string
  setKey?: any
}

const SampleForm = React.forwardRef((props: Props, ref) => {
  const {
    control: sampleControl,
    setError: setSampleError,
    handleSubmit: handleSampleSubmit,
    formState: { errors: sampleErrors },
    reset
  } = useForm({
    defaultValues: props.formField.sample,
    mode: 'onBlur',
    resolver: yupResolver(sampleSchema)
  })

  const {
    control: patientControl,
    setError: setPatientError,
    handleSubmit: handlePatientSubmit,
    formState: { errors: patientErrors }
  } = useForm({
    defaultValues: props.formField.patient,
    mode: 'onBlur',
    resolver: yupResolver(patientSchema)
  })

  const {
    control: clinicalControl,
    setError: setClinicalError,
    handleSubmit: handleClinicalSubmit,
    formState: { errors: clinicalErrors }
  } = useForm({
    defaultValues: props.formField.clinical,
    mode: 'onBlur',
    resolver: yupResolver(clinicalSchema)
  })

  const stepPatientRef = React.useRef<any>()

  React.useImperativeHandle(ref, () => {
    return {
      handleFqUpload: stepPatientRef.current?.handleFqUpload,
      deleteFqFile: stepPatientRef.current?.deleteFqFile
    }
  })

  return (
    <Box paddingTop={5} paddingRight={5}>
      <StepSample
        sample={props.formField.sample}
        fn={(type: any, value: any) => {
          console.log(type, value)

          props.getValues(type, value, props.index)
        }}
        control={sampleControl}
        errors={sampleErrors}
        sampleType={props.sampleType}
        setValue={reset}
      />

      <StepPatient
        ref={stepPatientRef}
        control={patientControl}
        errors={patientErrors}
        patient={props.formField.patient}
        fn={(type: any, value: any) => {
          console.log(type, value)

          props.getValues(type, value, props.index)
        }}
        handleUploads={props.handleUploads}
        sampleType={props.sampleType}
        setKey={props.setKey}
      />

      <StepClinical
        clinical={props.formField.clinical}
        fn={(type: any, value: any) => {
          props.getValues(type, value, props.index)
        }}
      />
    </Box>
  )
})

export default SampleForm
